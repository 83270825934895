import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Page from './Page';
import { gameQuery } from '../queries/myGames';
import TransitionScreen from './navigation/TransitionScreen';
import { pxToRem } from '../styles/unitConverter';
import { gray40 } from '../styles/waveColors';
import { noticeError } from '../lib/newrelic';

const GameContainer = glamorous.iframe({
    width: '100vw',
    minHeight: '983px',
    border: 'none',
});

const NoGameFoundContainer = glamorous.div({
    textAlign: 'center',
    marginTop: pxToRem(80),
    marginBottom: pxToRem(80),
    '& img': {
        marginBottom: pxToRem(15),
    },
    '& div': {
        color: gray40,
        fontWeight: 'bold',
        width: pxToRem(252),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const renderNoGameFoundView = ({ assetsPath }) => {
    return h(NoGameFoundContainer, {}, [
        h('img', {
            src: `${assetsPath}/myBookings/cancelled.svg`,
        }),
        h('div', {}, 'Spiel nicht gefunden.'),
    ]);
};

function MyGame(props, { config: { assetsPath } }) {
    try {
        const { gameId } = useParams();

        const { loading, error, data } = useQuery(gameQuery, {
            variables: { gameId },
        });

        if (loading) {
            return h(TransitionScreen);
        }

        if (error || (data && !data.game.ok)) {
            throw data && !data.game.ok ? new Error('Cant find game') : error;
        }

        return h(
            Page,
            {
                pagename: '/mhc/meine-spiele',
                title: 'Meine Spiele',
            },
            [
                h(GameContainer, {
                    src: data.game.url,
                }),
            ],
        );
    } catch (error) {
        noticeError(error, {
            pageName: 'MyGame',
        });
        return renderNoGameFoundView({ assetsPath });
    }
}

MyGame.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default MyGame;
