import moment from 'moment';
import { useMemo } from 'react';

import { useRentalcarCampaigns } from './useRentalcarCampaigns';

export const getWorthFromCampaign = (campaign, locale) => {
    if (!campaign) return { worth: 0, currency: 'EUR' };
    const worths = campaign.coupon.worth.filter(
        (worth) =>
            worth.domain === `${locale}-HCHECK` || worth.domain === 'ALL',
    );

    return worths[0].value.reduce(
        (maxWorth, currentWorth) => {
            return currentWorth.worth > maxWorth.worth
                ? currentWorth
                : maxWorth;
        },
        { worth: 0, currency: 'EUR' },
    );
};

export const useCurrentCampaign = (campaigns, offer) => {
    return useMemo(() => {
        const now = moment();
        return campaigns
            .filter((campaign) => {
                return (
                    campaign.active &&
                    moment(campaign.start).isSameOrBefore(now) &&
                    moment(campaign.end).isSameOrAfter(now)
                );
            })
            .sort((a, b) => moment(a.start).diff(moment(b.start)))[0];
    }, [campaigns, offer && offer.discountCouponApplied]);
};

export const useCampaignState = (context, offer) => {
    const campaigns = useRentalcarCampaigns(context);
    const currentCampaign = useCurrentCampaign(campaigns, offer);
    const hasCampaign = currentCampaign !== undefined;

    return {
        campaigns,
        currentCampaign,
        hasCampaign,
    };
};
