import { useEffect } from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { getAdvantages, getCardConfigKey, getImageName } from './config';
import { RentalcarCardContainer } from '../components/RentalcarCardContainer';
import CarWheel from '../../icons/CarWheel';
import { waveTextMMedium, waveTextS } from '../../../styles/waveText';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { waveHeadline2XS } from '../../../styles/waveHeadlines';
import { SlimCheckmark } from '../../icons/Checkmark';
import { Offer } from './Offer';
import { Loading } from './Loading';
import { getUtmParameters } from '../RentalcarCard';
import useRentalcarSearch from '../useRentalcarSearch';
import useSearchOffer from '../useSearchOffer';
import { RentalcarButton } from '../components/RentalcarButton';
import { renderImage } from '../components/RentalcarImage';
import { Bonuses } from './Bonuses';

const CardContainer = glamorous(RentalcarCardContainer)({
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: 0,
    position: 'relative',
    [waveMdMediaQuery]: {
        // override base style from RentalcarCardContainer
        margin: 0,
    },
    [waveLgMediaQuery]: {
        margin: 0,
        flexDirection: 'row',
    },
});

const SmallTitle = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    gap: options.space.xs,
    ...waveTextMMedium,
    color: options.colors.green40,
    fontWeight: 600,
});

const Title = glamorous.span({
    ...waveHeadline2XS,
});

const Content = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    padding: options.space.xl,
    [waveMdMediaQuery]: {
        flexGrow: 1,
        flexShrink: 0,
        padding: `${options.space.xl}px 0 ${options.space.m}px ${options.space.l}px`,
    },
});

const AdvantagesContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
        alignItems: 'end',
        gap: options.space.xl,
    },
});

const Advantages = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space['2xs'],
    width: 'fit-content',
});
const Advantage = glamorous.span({
    display: 'flex',
    alignItems: 'center',
    gap: options.space['2xs'],
});
const AdvantageText = glamorous.span({
    ...waveTextS,
});

const renderAdventage = (text) =>
    h(Advantage, [
        h(
            glamorous(SlimCheckmark)({
                color: options.colors.white,
                flexShrink: 0,
            }),
        ),
        h(AdvantageText, text),
    ]);

const NoOfferContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
});

const NoOfferFoundText = glamorous.p({
    ...waveTextS,
    color: options.colors.blue,
    margin: 0,
});

const HCMietwagenLogo = glamorous.img({
    position: 'absolute',
    right: options.space.m,
    top: options.space.m,
    width: 110,
    [waveMdMediaQuery]: {
        right: options.space.xl,
        top: options.space.xl,
        width: 134,
    },
});

const ButtonSection = glamorous.div({
    display: 'flex',
    gap: options.space.m,
    flexDirection: 'column-reverse',
    [waveSmMediaQuery]: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    [waveMdMediaQuery]: {
        gap: options.space.xl,
    },
});

const renderTitleAndAdvantages = ({
    advantages,
    title = 'Unsere Mietwagen-Empfehlung',
}) => [
    h('div', [
        h(SmallTitle, [
            h(CarWheel, { css: { width: '16px' } }),
            h('span', 'Nur hier und nur für Dich'),
        ]),
        h(Title, title),
    ]),
    h(AdvantagesContainer, [h(Advantages, advantages.map(renderAdventage))]),
];

const ButtonText = glamorous.span({
    '& .button-prefix': {
        display: 'none',
    },
    [waveMdMediaQuery]: {
        '& .button-prefix': {
            display: 'inline',
        },
    },
});

const defaultSearchOffer = ({
    context,
    hasCampaign,
    defaultOfferCardTitle,
    currentCampaign,
    buttonPrefix,
}) => {
    const baseButtonText = 'Mietwagen finden';
    const buttonText = h(ButtonText, [
        buttonPrefix &&
            h('span', { className: 'button-prefix' }, [buttonPrefix, ' ']),
        baseButtonText,
    ]);

    return h(CardContainer, [
        h(Content, [
            h(
                renderTitleAndAdvantages({
                    advantages: getAdvantages(
                        hasCampaign ? 'defaultVoucher' : 'default',
                    ),
                    title: defaultOfferCardTitle,
                }),
            ),
            h(ButtonSection, [
                h(
                    RentalcarButton,
                    {
                        href: `/mietwagen?utm_source=mHC&utm_medium=Hub&utm_campaign=TravelNoCarNo&b2bPartnerSource=hc_mhc_${context.config.topLevelDomain}`,
                    },
                    buttonText,
                ),
                hasCampaign &&
                    h(Bonuses, {
                        couponApplied: true,
                        campaign: currentCampaign,
                        isPremiumMember: false,
                        locale: context.config.locale,
                    }),
            ]),
        ]),
        h(
            renderImage(
                'default',
                `${context.config.assetsPath}/hcMietwagen/default.jpg`,
            ),
        ),
        h(HCMietwagenLogo, {
            src: `${context.config.assetsPath}/hcMietwagen/holidayCheckMietwagenLogo.svg`,
        }),
    ]);
};

function RentalcarOfferCard({
    hcTravel,
    context,
    showDefaultSearchOffer,
    utmParameter,
    eventCategory,
    offerCardTitle,
    defaultOfferCardTitle,
    buttonPrefix,
    currentCampaign,
    hasCampaign,
}) {
    if (showDefaultSearchOffer) {
        return defaultSearchOffer({
            context,
            hasCampaign,
            defaultOfferCardTitle,
            currentCampaign,
            buttonPrefix,
        });
    }
    const cardConfigKey = getCardConfigKey(hcTravel.booking.travellers);

    useEffect(() => {
        context.trackEvent({
            event: 'event',
            eventCategory,
            eventAction: `displayCard=${cardConfigKey}`,
            eventLabel: 'Rentalcar - Offercard',
        });
    }, []);

    const { search, searchData } = useRentalcarSearch(context, hcTravel);

    const { offer, isLoading } = useSearchOffer(
        search,
        hcTravel,
        false,
        context,
    );

    const advantages = getAdvantages(cardConfigKey);

    if (search) {
        return h(CardContainer, [
            h(Content, [
                h(
                    renderTitleAndAdvantages({
                        advantages,
                        title: offerCardTitle,
                    }),
                ),
                isLoading && h(Loading, { isFinished: !isLoading }),
                !isLoading &&
                    offer &&
                    h(Offer, {
                        cardType: cardConfigKey,
                        linkToListing: cardConfigKey === 'default',
                        hcTravel,
                        search,
                        searchData,
                        offer,
                        context,
                        utmParameter,
                        currentCampaign,
                        hasCampaign,
                    }),
                !isLoading &&
                    !offer &&
                    h(NoOfferContainer, [
                        h(
                            NoOfferFoundText,
                            'Wir haben kein aktuelles Angebot gefunden.',
                        ),
                        h(
                            RentalcarButton,
                            {
                                href: `/mietwagen?utm_source=mHC&utm_medium=Hub&utm_campaign=noOfferFoundRedirect`,
                            },
                            'Mietwagen finden',
                        ),
                    ]),
            ]),
            h(
                renderImage(
                    cardConfigKey,
                    `${context.config.assetsPath}/hcMietwagen/${getImageName(cardConfigKey)}`,
                ),
            ),
        ]);
    }

    return h(RentalcarOfferCard, {
        hcTravel,
        context,
        showDefaultSearchOffer: true,
        utmParameter: getUtmParameters(true, false),
        eventCategory,
        offerCardTitle,
        defaultOfferCardTitle,
        currentCampaign,
        hasCampaign,
    });
}

export { RentalcarOfferCard };
