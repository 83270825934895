import { useEffect, useState } from 'react';

import fetchRentalcarCampaign from '../../fetchers/fetchRentalcarCampaign';

const useRentalcarCampaigns = function (context) {
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        const fetchCampaign = async () => {
            const campaignsResponse = await fetchRentalcarCampaign(context);

            setCampaigns(campaignsResponse || []);
        };

        fetchCampaign();
    }, []);

    return campaigns;
};

export { useRentalcarCampaigns };
