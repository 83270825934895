import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';
import { noticeError } from '../lib/newrelic';

const query = stripIndent`
        query RootQuery {
          rentalcarCampaigns {
              code
              tpe
              active
              start
              end
              coupon {
                validFrom
                validTo
                worth {
                  domain
                  value {
                    worth
                    currency
                    validDestinationRegex
                  }
                }
                condition {
                  domain
                  value {
                    conditionText
                  }
                }
              }
          }
        }
    `;

export default async function (context) {
    try {
        const response = await graphqlFetch(context, { query });

        if (response.ok) {
            const {
                data: { rentalcarCampaigns },
            } = await response.json();

            return rentalcarCampaigns;
        }
    } catch (error) {
        noticeError(error, {
            pageName: 'MyHolidaycheckDashboard',
            component: 'fetchRentalcarCampaign',
        });
    }

    return [];
}
