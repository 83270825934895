import { useState, useEffect } from 'react';

import fetchRentalcarOfferCardState from '../../fetchers/fetchRentalcarOffercardState';

export const useRentalcarOfferState = (context, currentBooking, hcTravel) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const fetchStatus = async () => {
            const status = await fetchRentalcarOfferCardState(context);
            setIsActive(status);
        };

        fetchStatus();
    }, [context]);

    const shouldShowRentalcarOffer =
        isActive &&
        currentBooking === null &&
        hcTravel &&
        hcTravel.booking.travelkind === 'PACKAGE';

    return { isActive, shouldShowRentalcarOffer };
};
