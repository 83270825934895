import moment from 'moment/moment';

export const getValidHcmBooking = (bookings) => {
    if (!bookings || !Array.isArray(bookings) || bookings.length === 0) {
        return {
            hasBookings: false,
            validBooking: null,
        };
    }

    const now = moment();
    const futurePendingConfirmedBooking = bookings
        .filter((booking) => {
            const isStatusValid = ['Pending', 'Confirmed'].includes(
                booking.status,
            );
            const dropoffMoment = moment(booking.dropoffDatetime);
            const isFuture = dropoffMoment.isAfter(now);
            return isStatusValid && isFuture;
        })
        .sort((a, b) =>
            moment(a.dropoffDatetime).diff(moment(b.dropoffDatetime)),
        );
    const validBooking =
        futurePendingConfirmedBooking[0] &&
        futurePendingConfirmedBooking[0].id &&
        futurePendingConfirmedBooking[0].pickupStation.countryName
            ? {
                  bookingId: futurePendingConfirmedBooking[0].id.toString(),
                  countryName:
                      futurePendingConfirmedBooking[0].pickupStation
                          .countryName,
                  regionName:
                      futurePendingConfirmedBooking[0].carOfferDestinationRegion
                          .destination,
                  countryDestinationId:
                      futurePendingConfirmedBooking[0].hcDestination.countryId,
              }
            : null;

    return {
        hasBookings: true,
        validBooking,
    };
};
