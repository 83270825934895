import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { useQuery } from '@apollo/client';

import { useFetchRentalCarBooking } from '../../../../rentalcarBookingCard/useFetchRentalCarBooking';
import { RentalcarOfferCard } from '../../../../rentalcarBookingCard/RentalcarOfferCard';
import { RentalcarBookingCard } from '../../../../rentalcarBookingCard/RentalcarBookingCard';
import { useRentalcarOfferState } from '../../../../rentalcarBookingCard/useRentalcarOfferState';
import { userProfileCard } from '../../../../../queries/myHolidaycheckDashboard';
import {
    getWorthFromCampaign,
    useCampaignState,
} from '../../../../rentalcarBookingCard/useCurrentCampaign';
import { getCardTitles } from '../../utils/getCardTitles';

const ComponentContainer = glamorous.section({
    backgroundColor: options.colors.white,
    borderRadius: options.space.xs,
    maxWidth: 894,
    margin: `0 ${options.space.xl}px`,
});

function MietwagenBooking({ myRef, context }) {
    const { bookings, hcTravel } = useFetchRentalCarBooking(context);
    const currentBooking = bookings[0] || null;
    const { shouldShowRentalcarOffer } = useRentalcarOfferState(
        context,
        currentBooking,
        hcTravel,
    );

    const eventCategory = 'HCMHub';
    const { data: profile } = useQuery(userProfileCard, {
        fetchPolicy: 'cache-and-network',
    });

    const offer = currentBooking && currentBooking.offer;
    const { currentCampaign, hasCampaign } = useCampaignState(context, offer);
    const campaignWorth = getWorthFromCampaign(
        currentCampaign,
        context.config.locale,
    );

    const { offerCardTitle, defaultOfferCardTitle } = getCardTitles(
        profile,
        hasCampaign,
        campaignWorth,
        true,
    );

    if (currentBooking !== null) {
        return h(ComponentContainer, { innerRef: myRef }, [
            h(RentalcarBookingCard, {
                id: currentBooking.id,
                status: currentBooking.status,
                destinationName: currentBooking.carOfferDestination,
                pickupDatetime: currentBooking.pickupDatetime,
                dropoffDatetime: currentBooking.dropoffDatetime,
                carOfferDestinationInfo:
                    currentBooking.carOfferDestinationRegion,
                carOfferPictureUrl: currentBooking.carOfferPictureUrl,
                transferType: currentBooking.transferType,
                trackEvent: context.trackEvent,
                eventCategory,
                context,
                hcTravel,
                isRentalcarHub: true,
            }),
        ]);
    }

    return h(ComponentContainer, { innerRef: myRef }, [
        h(RentalcarOfferCard, {
            hcTravel,
            context,
            showDefaultSearchOffer: !shouldShowRentalcarOffer,
            utmParameter: {
                // prettier-ignore
                'utm_source': 'mHC', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_medium': eventCategory, // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_campaign': 'PackageYesCarNo', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
            },
            eventCategory,
            ...(offerCardTitle && { offerCardTitle }),
            ...(defaultOfferCardTitle && { defaultOfferCardTitle }),
            buttonPrefix: 'Jetzt',
            currentCampaign,
            hasCampaign,
        }),
    ]);
}

MietwagenBooking.displayName = 'Mein Mietwagen';
MietwagenBooking.propTypes = {
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    context: PropTypes.any,
    hubDestinationId: PropTypes.string,
    articleDestinationId: PropTypes.string,
    bookingId: PropTypes.string,
    regionName: PropTypes.string,
};
MietwagenBooking.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
    window: PropTypes.object.isRequired,
};
export { MietwagenBooking };
