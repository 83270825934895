import moment from 'moment/moment';

export const CASHBACK_TYPES = {
    CAR_RENTAL_REGULAR: 'CAR_RENTAL_REGULAR',
    PROMOTIONAL: 'PROMOTIONAL',
    REGULAR: 'REGULAR',
};

export const CAR_RENTAL_CASHBACK_ITEM = {
    type: 'CAR_RENTAL_REGULAR',
    detail: {
        cashback: {
            amount: 100,
        },
        travelPriceThreshold: {
            amount: 490,
        },
        status: 'OPEN',
        marketing: {
            name: 'Mietwagengutschein',
            description: 'Die Gutscheinhöhe ist abhängig vom Mietwagenpreis.',
            redeemInformation: {
                link: {
                    innerText: 'Mietwagen finden',
                    href: '/mietwagen',
                },
                text: 'Nutze für die Buchung die E-Mail-Adresse Deiner Premium-Mitgliedschaft. Gutschein wird automatisch angewandt.',
            },
        },
    },
};

export const filterOpenCashback = (cashbackItem) =>
    cashbackItem.detail.status === 'OPEN';

export const filterPromotionalCashbackWithValidityPeriod = (cashbackItem) =>
    !(cashbackItem.detail.isPromotional && !cashbackItem.detail.validityPeriod);

export const filterByOpenAndPromotionalWithValidityCashback = (cashbackItem) =>
    filterOpenCashback(cashbackItem) &&
    filterPromotionalCashbackWithValidityPeriod(cashbackItem);

export const parseCashbackItem = ({ cashbackItem, periodEndDate }) => {
    const cashbackItemInformation = {
        cashbackItemTypeName: cashbackItem.detail.marketing.name,
        periodEndDateFormatted: moment(periodEndDate).format('DD.MM.YYYY'),
        cashbackItemRedeemTitleText:
            cashbackItem.detail.marketing.redeemInformation.text,
        cashbackItemRedeemText: cashbackItem.detail.marketing.description,
        cashbackItemRedeemLinkText:
            cashbackItem.detail.marketing.redeemInformation.link.innerText,
        cashbackItemRedeemLinkHref:
            cashbackItem.detail.marketing.redeemInformation.link.href,
    };

    if (cashbackItem.detail.isPromotional) {
        return {
            ...cashbackItemInformation,
            validityStartDateFormatted: moment(
                cashbackItem.detail.validityPeriod.startDate,
            ).format('DD.MM'),
            validityEndDateFormatted: moment(
                cashbackItem.detail.validityPeriod.endDate,
            ).format('DD.MM.YYYY'),
        };
    } else if (cashbackItem.type === CASHBACK_TYPES.CAR_RENTAL_REGULAR) {
        return {
            ...cashbackItemInformation,
            cashbackItemRedeemTextLink: ' Die Staffel findest Du hier.',
            cashbackItemRedeemTextHref:
                'https://www.holidaycheck.de/premium/mietwagen',
        };
    }

    return cashbackItemInformation;
};

export const extractAvailableCashbackItem = (activePeriod) => {
    const cashback = activePeriod.benefits.cashback;
    const periodEndDate = activePeriod.periodEndDate;

    const availableCashback = cashback
        .filter(filterByOpenAndPromotionalWithValidityCashback)
        .filter((cashbackItem) => {
            if (!cashbackItem.detail.validityPeriod) return true;

            const startDate = moment(
                cashbackItem.detail.validityPeriod.startDate,
            );
            const endDate = moment(cashbackItem.detail.validityPeriod.endDate);
            const now = moment();

            return now.isBetween(startDate, endDate);
        });
    const cashbackWithCarRental = availableCashback.concat([
        CAR_RENTAL_CASHBACK_ITEM,
    ]);

    const cashbackItem = cashbackWithCarRental[0];

    return {
        cashbackItem,
        ...parseCashbackItem({
            cashbackItem,
            periodEndDate,
        }),
    };
};
