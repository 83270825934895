import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import * as glamor from 'glamor';

import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../../../../styles/waveMediaQueries';
import DarkLink from '../../../components/DarkLink';

const isVisibleOnMobile = (fullWidth, articleAmount) => {
    return fullWidth || articleAmount === -1;
};

const isVisibleOnSm = (fullWidth, articleAmount) => {
    if (articleAmount === -1) return true;
    if (fullWidth && articleAmount === 1) return false;
    if (!fullWidth && articleAmount >= 2) return false;
    return true;
};

const isVisibleOnLg = (fullWidth, articleAmount) => {
    if (articleAmount === -1) return true;
    if (!fullWidth && [1, 2].includes(articleAmount)) return true;
    if (fullWidth && [0, 3].includes(articleAmount)) return true;
    return false;
};

const Container = glamorous.div(({ fullWidth, articleAmount }) => ({
    display: isVisibleOnMobile(fullWidth, articleAmount) ? 'block' : 'none',
    flexBasis: '100%',
    [waveSmMediaQuery]: {
        display: isVisibleOnSm(fullWidth, articleAmount) ? 'block' : 'none',
    },
    [waveLgMediaQuery]: {
        display: isVisibleOnLg(fullWidth, articleAmount) ? 'block' : 'none',
    },
}));

const BannerContainer = glamorous.div({
    position: 'relative',
    display: 'block',
    height: '100%',
    borderRadius: options.radii.small,
    background:
        'radial-gradient(23.75% 52.68% at 79.78% 50%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(275deg, #FFC700 38.83%, #F0AD01 63.28%)',
    color: options.colors.white,
    overflow: 'hidden',
    ':hover, :active, :focus, :focus-within': {
        color: options.colors.white,
    },
});

const ContentContainer = glamorous.div(({ fullWidth }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${options.space.xl}px ${options.space.m}px`,
    flexDirection: 'column',
    gap: fullWidth ? `${options.space.xs}px` : 'unset',
    height: `calc(100% - ${options.space['4xl']}px)`,
    [waveSmMediaQuery]: {
        gap: fullWidth ? `${options.space.xl}px` : 'unset',
    },
}));

const Headline = glamorous.h3(({ fullWidth }) => ({
    fontSize: `${options.fontSizes.l}px`,
    lineHeight: `${options.space.xl}px`,
    fontWeight: 800,
    margin: 0,
    marginBottom: options.space['2xs'],
    '> span': {
        whiteSpace: 'nowrap',
    },
    [waveSmMediaQuery]: {
        maxWidth: fullWidth ? 'calc(100% - 200px)' : 'unset',
    },
    [waveLgMediaQuery]: {
        flexGrow: 1,
        fontSize: fullWidth
            ? `${options.fontSizes.xl}px`
            : `${options.fontSizes.l}px`,
        lineHeight: fullWidth
            ? `${options.fontSizes['2xl']}px`
            : `${options.space.xl}px`,
    },
}));

const ActionButton = glamorous.div({
    border: '1px solid #FFF',
    padding: `0 ${options.space.l}px`,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 700,
    borderRadius: '100px',
    height: '40px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'start',
    textTransform: 'uppercase',
});

const Badge = glamorous.div(({ fullWidth }) => ({
    backgroundColor: '#DE9E24',
    width: '88px',
    height: '88px',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: `${options.space.xs}px`,
    alignSelf: 'end',
    zIndex: 2,
    [waveSmMediaQuery]: {
        position: fullWidth ? 'absolute' : 'relative',
        right: fullWidth ? `${options.space['2xl']}px` : 'unset',
        top: fullWidth ? '50%' : 'unset',
        transform: fullWidth ? 'translateY(-50%)' : 'unset',
    },
    [waveMdMediaQuery]: {
        right: fullWidth ? options.space['6xl'] : 'unset',
    },
}));

const BadgeExtra = glamorous.span({
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px',
});

const TripplannerTitle = glamorous.div(({ articleAmount }) => ({
    margin: `${options.space.l}px 0 ${options.space.m}px 0`,
    fontWeight: 700,
    display: articleAmount === 0 ? 'none' : 'block',
}));

const waveAnimation = glamor.css.keyframes({
    '0%': {
        transform: 'translateX(0)',
    },
    '100%': {
        transform: 'translateX(-50%)',
    },
});

const WaveContainer = glamorous.div({
    width: '100%',
    height: '100%',
    position: 'absolute',
    transform: 'scale(1.3) translateX(-4%) translateY(35px)',
    [waveLgMediaQuery]: {
        transform: 'unset',
    },
});

const Wave1 = glamorous.img({
    position: 'absolute',
    width: 'unset',
    animationName: waveAnimation,
    animationDuration: '7s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
});

const Wave2 = glamorous.img({
    position: 'absolute',
    width: 'unset',
    animationName: waveAnimation,
    animationDuration: '13s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
});

const Wave3 = glamorous.img({
    position: 'absolute',
    width: 'unset',
    animationName: waveAnimation,
    animationDuration: '31s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
});

function TripplannerBanner({
    assetsPath,
    destination,
    fullWidth,
    articleAmount,
}) {
    const utmParameter = `utm_source=mHC&utm_medium=HCMHub&utm_campaign=TripplannerClicked`;
    const baseUrl = '/mietwagen/erleben/ausflugsplaner';
    const url = destination
        ? `${baseUrl}?destination=${destination}&${utmParameter}`
        : `${baseUrl}?${utmParameter}`;

    return [
        h(
            Container,
            { key: 'tripplanner-container', fullWidth, articleAmount },
            [
                h(DarkLink, { href: url, key: url, target: '_blank' }, [
                    fullWidth
                        ? h(
                              TripplannerTitle,
                              { articleAmount },
                              'Du möchtest Deine Route selbst zusammen stellen?',
                          )
                        : [],
                    h(BannerContainer, [
                        h(WaveContainer, [
                            h(Wave2, {
                                src: `${assetsPath}/hcMietwagen/tripplannerBanner/wave2.svg`,
                            }),
                            h(Wave1, {
                                src: `${assetsPath}/hcMietwagen/tripplannerBanner/wave1.svg`,
                            }),
                            h(Wave3, {
                                src: `${assetsPath}/hcMietwagen/tripplannerBanner/wave3.svg`,
                            }),
                        ]),
                        h(ContentContainer, { fullWidth }, [
                            h(Headline, { fullWidth }, [
                                'Unser KI-Ausflugsplanner für Deinen Urlaub!',
                            ]),
                            h(Badge, { fullWidth }, [
                                'Jetzt ',
                                h(BadgeExtra, ['Neu']),
                            ]),
                            h(ActionButton, ['Planer starten']),
                        ]),
                    ]),
                ]),
            ],
        ),
    ];
}

export default TripplannerBanner;
