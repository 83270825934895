import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import { useRef } from 'react';

import { MietwagenKnowledge } from './sections/MietwagenKnowledge/MietwagenKnowledge';
import { Container } from './components/Container';
import { ContentContainer } from './components/ContentContainer';
import { MietwagenExperience } from './sections/MietwagenExperience/MietwagenExperience';
import { Menu } from './components/Menu';
import { getTravelData } from './utils/getTravelData';
import { MietwagenBooking } from './sections/MietwagenBooking/MietwagenBooking';

const detailSections = [
    MietwagenBooking,
    MietwagenKnowledge,
    MietwagenExperience,
];

function MietwagenHub(props, context) {
    const makeSection = (component) => ({
        component,
        indexEntryText: component.displayName,
        displayName: component.displayName,
        ref: useRef(null),
    });

    const {
        articleDestinationId,
        hubDestinationId,
        bookingId,
        regionName,
        hasHcmBookings,
    } = getTravelData(context);
    const sections = detailSections.map(makeSection);

    const indexEntries = [];
    const sectionEntries = [];

    for (const { component, ref, indexEntryText, displayName } of sections) {
        sectionEntries.push(
            h(component, {
                myRef: ref,
                displayName,
                context,
                articleDestinationId,
                hubDestinationId,
                bookingId,
                regionName,
            }),
        );
        if (indexEntryText) {
            indexEntries.push({
                name: indexEntryText,
                myRef: ref,
                displayName,
            });
        }
    }

    return h(Container, [
        h(Menu, { entries: indexEntries, hasHcmBookings }),
        h(ContentContainer, sectionEntries),
    ]);
}

MietwagenHub.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
    window: PropTypes.object.isRequired,
};

export default MietwagenHub;
