import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { ComponentContainer } from '../../components/ComponentContainer';
import { HeaderContainer } from '../../components/Card/CardHeaderContainer';
import { Title } from '../../components/Card/CardTitle';
import { HeaderImage } from '../../components/Card/CardHeaderImage';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../../../styles/waveMediaQueries';
import MietwagenExperienceArticles from './components/MietwagenExperienceArticles';
import MietwagenExperienceDefault from './components/MietwagenExperienceDefault';
import { useRentalcarArticles } from '../../utils/useRentalcarArticles';

const TextContainer = glamorous.div({
    order: 1,
    [waveSmMediaQuery]: {
        order: -1,
    },
});

const HeaderImageContainer = glamorous.div({
    position: 'relative',
});

const ExperienceCarImage = glamorous.img({
    position: 'absolute',
    bottom: options.space.m,
    left: options.space.xs,
    width: 85,
    border: '4px solid white',
    outline: '1px solid #DADADA',
    transform: 'rotate(4.281deg)',
    [waveMdMediaQuery]: {
        width: 110,
    },
});

const ExperienceSeperatorOnlyXs = glamorous.img({
    width: 123,
    margin: `-${options.space['2xl']}px ${options.space['4xl']}px ${options.space.m}px auto`,
    display: 'block',
    [waveSmMediaQuery]: {
        display: 'none',
    },
});

const ExperienceSeperatorMinSm = glamorous.img({
    display: 'none',
    [waveSmMediaQuery]: {
        display: 'block',
        width: 379,
        height: 70,
        margin: `-${options.space['2xl']}px 94px ${options.space.m}px auto`,
        transform: 'unset',
    },
    [waveMdMediaQuery]: {
        width: 516,
        height: 63,
        margin: `-${options.space['2xl']}px 150px ${options.space['2xl']}px auto`,
    },
});

function MietwagenExperience({
    myRef,
    context,
    articleDestinationId,
    bookingId,
    regionName,
}) {
    const hasFutureBooking = articleDestinationId || bookingId;
    const experienceArticles = useRentalcarArticles(
        context,
        articleDestinationId,
        bookingId,
    );

    const title = hasFutureBooking
        ? 'Die Vorfreude steigern'
        : 'Lass Dich inspirieren';
    const description = hasFutureBooking
        ? 'Checke unsere Routen und Tagestrips, die wir für Deine Reise mit dem Mietwagen vorbereitet haben.'
        : 'Du bist Dir nicht sicher, ob Du einen Mietwagen brauchst? Checke unsere Routen und Tagestrips.';

    return h('div', [
        h(ExperienceSeperatorMinSm, {
            src: `${context.config.assetsPath}/hcMietwagen/seperator1.svg`,
        }),
        h(ExperienceSeperatorOnlyXs, {
            src: `${context.config.assetsPath}/hcMietwagen/seperator2.svg`,
        }),
        h(ComponentContainer, { innerRef: myRef }, [
            h(HeaderContainer, [
                h(TextContainer, [h(Title, title), h('div', description)]),
                h(HeaderImageContainer, [
                    h(HeaderImage, {
                        src: `${context.config.assetsPath}/hcMietwagen/experience/experience.svg`,
                    }),
                    h(ExperienceCarImage, {
                        src: `${context.config.assetsPath}/hcMietwagen/experience/experience-car.jpg`,
                    }),
                ]),
            ]),
            hasFutureBooking
                ? h(MietwagenExperienceArticles, {
                      context,
                      experienceArticles,
                      regionName,
                  })
                : h(MietwagenExperienceDefault, context),
        ]),
    ]);
}

MietwagenExperience.displayName = 'Erleben';
MietwagenExperience.propTypes = {
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    context: PropTypes.any,
    articleDestinationId: PropTypes.string,
    hubDestinationId: PropTypes.string,
    bookingId: PropTypes.string,
    regionName: PropTypes.string,
};

export { MietwagenExperience };
