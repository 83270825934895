import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { InfoOutline } from '@designsystem/icons';
import { useState } from 'react';

import { waveTextSBold } from '../../../styles/waveText';
import { formatPriceShort } from '../../myBookings/payment/formatPrice';
import Tooltip from './Tooltip';

const BadgeContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: options.space.xs,
    flexShrink: 0,
});

const BadgeElement = glamorous.div(({ backgroundColor }) => ({
    padding: `${options.space['2xs']}px ${options.space.xs}px`,
    backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: options.space['2xs'],
    alignItems: 'center',
    borderRadius: options.radii.small,
}));

const BadgeIconWrapper = glamorous.span({
    position: 'relative',
});

const BadgeText = glamorous.span(({ color }) => ({
    ...waveTextSBold,
    color,
}));

const Badge = ({ text, info, backgroundColor, color }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return h(BadgeElement, { backgroundColor }, [
        h(BadgeText, { color }, text),
        info !== null &&
            h(
                glamorous.span({
                    color,
                    height: options.space.m,
                    width: options.space.m,
                }),
                [
                    h(BadgeIconWrapper, [
                        h(InfoOutline, {
                            width: 16,
                            height: 16,
                            fill: color,
                            onMouseEnter: () => setShowTooltip(true),
                            onMouseLeave: () => setShowTooltip(false),
                        }),
                        showTooltip && h(Tooltip, { text: info, width: 200 }),
                    ]),
                ],
            ),
    ]);
};

function Bonuses({
    couponApplied,
    campaign,
    isPremiumMember,
    premiumDiscount,
}) {
    return h(BadgeContainer, [
        campaign &&
            couponApplied &&
            h(Badge, {
                text: campaign.code,
                info: 'Mit Klick auf "Mietwagen finden" wird der Rabatt automatisch bei Deiner Buchung angerechnet.',
                backgroundColor: options.colors.green100,
                color: options.colors.green40,
            }),
        isPremiumMember &&
            h(Badge, {
                text: `${formatPriceShort(premiumDiscount * -1, 'EUR')} Premium`,
                info: null,
                backgroundColor: options.colors.purple100,
                color: options.colors.purple40,
            }),
    ]);
}

export { Bonuses };
