import { useEffect, useState } from 'react';

import { fetchRentalcarOffer } from '../../fetchers/fetchRentalcarOffer';
import { noticeError } from '../../lib/newrelic';
import { getLastOutboundFlight } from '../../lib/getLastOutboundFlight';

function track(
    foundAfter6,
    { travel, searchedOffer, isPremiumMember },
    trackEvent,
) {
    const eventLabel = `withPremium=${isPremiumMember}`;
    if (foundAfter6 && searchedOffer) {
        trackEvent({
            event: 'event',
            eventCategory: 'mHCHub - Rentalcar',
            eventAction: 'offerFoundAfter6Seconds',
            eventLabel,
        });
    } else if (!foundAfter6 && searchedOffer) {
        trackEvent({
            event: 'event',
            eventCategory: 'mHCHub - Rentalcar',
            eventAction: 'offerFoundAfter10Seconds',
            eventLabel,
        });
    } else {
        const lastOutboundFlight = getLastOutboundFlight(travel);
        const destinationAirportCode = lastOutboundFlight.arrival.airportCode;
        trackEvent({
            event: 'event',
            eventCategory: 'mHCHub - Rentalcar',
            eventAction: `noOfferFound=${destinationAirportCode}`,
            eventLabel,
        });
    }
}

/**
 *
 * @param search
 * @param hcTravel
 * @param isPremiumMember {boolean}
 * @param context
 * @returns {{offer:     {
 *     offerPageUrl: string,
 *     pictureUrl: string,
 *     carClass?: string,
 *     priceAmount: number,
 *     priceCurrency: string,
 *     destinationName?: string
 *     rentalDays: number,
 *     didOfferUpdate: boolean,
 *     discountCouponApplied?: boolean
 * } || null, isLoading: boolean}}
 */
const useSearchOffer = (search, hcTravel, isPremiumMember, context) => {
    const { setTimeout } = context.window;
    const { trackEvent } = context;
    const [offer, setOffer] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const searchOffer = async () => {
        try {
            const numberOfChildren = hcTravel.booking.travellers.filter(
                (traveller) =>
                    traveller.salutation === 'CHILD' || traveller.age <= 17,
            ).length;

            return fetchRentalcarOffer(
                search.carSearchId,
                {
                    isPremiumMember,
                    numberOfAdults:
                        hcTravel.booking.travellers.length - numberOfChildren,
                    numberOfChildren,
                },
                context,
            );
        } catch (error) {
            noticeError(error, {
                pageName: 'MyHolidaycheck',
                component: 'RentalcarCard',
            });

            return null;
        }
    };

    useEffect(() => {
        const handleNewSearch = async () => {
            setLoading(true);
            let foundAfter6 = true;
            await delay(6000);
            let searchedOffer = await searchOffer();

            if (!searchedOffer) {
                foundAfter6 = false;
                await delay(4000);
                searchedOffer = await searchOffer();
            }
            track(
                foundAfter6,
                { travel: hcTravel, searchedOffer, isPremiumMember },
                trackEvent,
            );

            setOffer(searchedOffer);
            setLoading(false);
        };

        const fetchOffer = async () => {
            if (!search) return;

            if (search.isNewSearch) {
                await handleNewSearch();
            } else {
                const searchedOffer = await searchOffer();

                setOffer(searchedOffer);
            }
        };

        fetchOffer();
    }, [search]);
    return {
        offer,
        isLoading,
    };
};

export default useSearchOffer;
