import gql from 'graphql-tag';

export const gameQuery = gql`
    query ($gameId: String!) {
        game(gameId: $gameId) {
            ok
            status
            url
        }
    }
`;
