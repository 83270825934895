import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { RentalcarBookingCard } from './RentalcarBookingCard';
import { useFetchRentalCarBooking } from './useFetchRentalCarBooking';
import { RentalcarOfferCard } from './RentalcarOfferCard';
import { useRentalcarOfferState } from './useRentalcarOfferState';
import { getWorthFromCampaign, useCampaignState } from './useCurrentCampaign';
import { getCardTitles } from '../myMietwagen/hub/utils/getCardTitles';
import { userProfileCard } from '../../queries/myHolidaycheckDashboard';

export const getUtmParameters = (hasHcTravel, hasMietwagen) => {
    const campaign = `Travel${hasHcTravel ? 'Yes' : 'No'}Car${hasMietwagen ? 'Yes' : 'No'}`;
    return `?utm_source=mHC&utm_medium=Hub&utm_campaign=${campaign}`;
};

const useCardData = (context, currentBooking) => {
    const offer = currentBooking && currentBooking.offer;
    const { currentCampaign, hasCampaign } = useCampaignState(context, offer);
    const campaignWorth = getWorthFromCampaign(
        currentCampaign,
        context.config.locale,
    );
    const { data: profile } = useQuery(userProfileCard, {
        fetchPolicy: 'cache-and-network',
    });

    const { offerCardTitle, defaultOfferCardTitle } = getCardTitles(
        profile,
        hasCampaign,
        campaignWorth,
    );

    return {
        currentCampaign,
        hasCampaign,
        offerCardTitle,
        defaultOfferCardTitle,
    };
};

/*
 * The card may display an active or upcoming rental car booking, prioritizing bookings
 * that are the same time as a recent HC Booking. If no related booking exists,
 * the next upcoming booking will be shown.
 * If there is a Hc Package Booking but no Rentalcar booking we will display an offerCard,
 * creating an actual search and offers with the data from the hcTravel
 * If no booking exists or all bookings are in the past, we will show a card that
 * links to the index page of HolidayCheck - Mietwagen.
 *
 * */
const RentalcarCard = (props, context) => {
    const { trackEvent } = context;
    const { bookings, hcTravel } = useFetchRentalCarBooking(context);

    const currentBooking = bookings[0] || null;
    const hasHcTravel = Boolean(hcTravel);
    const parameters = getUtmParameters(hasHcTravel, currentBooking !== null);
    const { shouldShowRentalcarOffer } = useRentalcarOfferState(
        context,
        currentBooking,
        hcTravel,
    );

    const eventCategory = 'mHCHub - Rentalcar';
    const {
        currentCampaign,
        hasCampaign,
        offerCardTitle,
        defaultOfferCardTitle,
    } = useCardData(context, currentBooking);

    if (shouldShowRentalcarOffer) {
        return h(RentalcarOfferCard, {
            hcTravel,
            context,
            utmParameter: {
                // prettier-ignore
                'utm_source': 'mHC', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_medium': 'Hub', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_campaign': 'PackageYesCarNo', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
            },
            eventCategory,
            currentCampaign,
            hasCampaign,
        });
    }

    return currentBooking !== null
        ? h(RentalcarBookingCard, {
              id: currentBooking.id,
              status: currentBooking.status,
              destinationName: currentBooking.carOfferDestination,
              pickupDatetime: currentBooking.pickupDatetime,
              dropoffDatetime: currentBooking.dropoffDatetime,
              carOfferDestinationInfo: currentBooking.carOfferDestinationRegion,
              carOfferPictureUrl: currentBooking.carOfferPictureUrl,
              transferType: currentBooking.transferType,
              trackEvent,
              eventCategory,
              context,
              hcTravel,
          })
        : h(RentalcarOfferCard, {
              hcTravel,
              context,
              showDefaultSearchOffer: true,
              utmParameter: parameters,
              eventCategory,
              offerCardTitle,
              defaultOfferCardTitle,
              currentCampaign,
              hasCampaign,
          });
};

export default RentalcarCard;

RentalcarCard.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
    window: PropTypes.object.isRequired,
};
