import glamorous from 'glamorous';
import h from 'react-hyperscript';

import { waveSmMediaQuery } from '../../../styles/waveMediaQueries';

const HCMBookingLogo = glamorous.img({
    position: 'absolute',
    right: 0,
    top: 0,
    width: 99,
    [waveSmMediaQuery]: {
        width: 126,
    },
});

export const renderBookingLogo = ({ context, bookingLogo }) => [
    h(HCMBookingLogo, {
        src: `${context.config.assetsPath}/hcMietwagen/booking/${bookingLogo}.svg`,
    }),
];
