import PropTypes from 'prop-types';
import h from 'react-hyperscript';

import MietwagenBooking from './MietwagenBooking';
import MietwagenBookingEmpty from './MietwagenBookingEmpty';
import { useRentalCarBooking } from './useRentalcarBooking';
import { useFetchBookingsAmount } from './useFetchBookingsAmount';
import { getImageUrl } from '../../../lib/rentalcar/getImageUrl';
import { BookingListContainer } from './utils/BookingListContainer';
import { BookingListTitle } from './utils/BookingListTitle';
import { RentalcarButton } from './utils/RentalcarButton';
import { BookingsContainer } from './utils/BookingsContainer';

function MietwagenSingleBooking(props, context) {
    const booking = useRentalCarBooking(context);

    const bookingsAmount = useFetchBookingsAmount(context);

    return h(BookingsContainer, [
        h(BookingListTitle, 'Meine Mietwagen-Buchungen'),
        h(BookingListContainer, [
            booking === null
                ? h(
                      MietwagenBookingEmpty,
                      {
                          message: 'Wir konnten diese Buchung nicht finden.',
                          config: context.config,
                      },
                      [
                          bookingsAmount > 0 &&
                              h(
                                  RentalcarButton,
                                  {
                                      href: `/mhc/mein-mietwagen/buchungen`,
                                  },
                                  'Meine Buchungen',
                              ),
                      ],
                  )
                : h(MietwagenBooking, {
                      isDefaultOpen: true,
                      booking,
                      titleImage: getImageUrl(
                          booking.pickupStation.countryName,
                      ),
                      key: booking.id,
                  }),
        ]),
    ]);
}

MietwagenSingleBooking.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
};

export default MietwagenSingleBooking;
