import gql from 'graphql-tag';

export const premiumMembership = gql`
    query {
        premiumMembership {
            personAccountDetail {
                mhcUUID
                isTestAccount
                accountStatus
                salutation
                firstName
                lastName
                birthdate
                email
                phone
                billingAddress {
                    street
                    postalCode
                    city
                    countryCode
                }
                paymentInformation {
                    currencyCode
                    paymentMethod
                    paymentTerm
                }
                winBackCounter
            }
            subscriptionContracts {
                subscriptionContractID
                isTestSubscriptionContract
                contractStatus
                hasPendingTermination
                revocationTerminationRequestDate
                contractStartDate
                contractEndDate
                nextRenewalDate
                paymentInformation {
                    currencyCode
                    paymentMethod
                    paymentTerm
                }
                subscriptionPeriods {
                    subscriptionPeriodID
                    isTestSubscriptionPeriod
                    periodStatus
                    periodProcessingStatus
                    periodDunningStatus
                    periodStartDate
                    periodEndDate
                    mainProductCode
                    discountProductCode
                    totalAmount
                    subscriptionYear
                    isWinBackPeriod
                    benefits {
                        cashback {
                            type
                            detail {
                                cashback {
                                    amount
                                    currencyCode
                                }
                                travelPriceThreshold {
                                    amount
                                }
                                validityPeriod {
                                    startDate
                                    endDate
                                }
                                status
                                bookingId
                                marketing {
                                    name
                                    description
                                    redeemInformation {
                                        link {
                                            innerText
                                            href
                                        }
                                        text
                                    }
                                }
                                isPromotional
                            }
                        }
                        partners {
                            redeemInformation {
                                button {
                                    label
                                    url
                                }
                                coupons {
                                    code
                                    label
                                }
                                text
                            }
                            label {
                                highlightedText
                                prefix
                            }
                            name
                            description
                            image
                            boldword
                        }
                    }
                }
                currentSubscriptionYear
            }
        }
    }
`;
