import { formatPriceShort } from '../../../myBookings/payment/formatPrice';

export const getCardTitles = (
    profile,
    hasCampaign,
    campaignWorth,
    isHcmHub,
) => {
    const givenName = profile && profile.profile && profile.profile.givenName;

    const offerCardTitle = givenName
        ? `${givenName}, erlebe Deine Reise mit dem Mietwagen`
        : undefined;

    const baseText = hasCampaign
        ? `spare ${formatPriceShort(campaignWorth.worth, campaignWorth.currency)} bei Deinem Mietwagen!`
        : '';
    const titleText =
        givenName && isHcmHub
            ? `${givenName}, ${baseText}`
            : baseText.charAt(0).toUpperCase() + baseText.slice(1);

    const defaultOfferCardTitle = hasCampaign ? titleText : undefined;

    return {
        offerCardTitle,
        defaultOfferCardTitle,
    };
};
