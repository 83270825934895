const images = {
    default: 'other.jpg',
    couple: 'coupleMaleFemale.jpg',
    family: 'family.jpg',
};

const advantagesTexts = {
    default: [
        'Voll abgesichert dank HolidayCheck All-Inclusive-Paket',
        'Günstigster Preis und kostenlos stornierbar',
        'Reise und Mietwagen aus einer Hand',
    ],
    defaultVoucher: [
        'Kostenlos stornierbar',
        'Günstiger findest Du es nirgends',
        'Für so viele Buchungen wie Du willst',
    ],
    couple: [
        'Ein Fahrzeug mit genug Platz für das Gepäck',
        'Voll abgesichert dank HolidayCheck All-Inclusive-Paket',
        'Günstigster Preis und kostenlos stornierbar',
    ],
    family: [
        'Platz für Familie und Gepäck',
        'Voll abgesichert dank HolidayCheck All-Inclusive-Paket',
        'Günstigster Preis und kostenlos stornierbar',
    ],
};

const getImageName = (type) => images[type] || images.default;

const getAdvantages = (type) =>
    advantagesTexts[type] || advantagesTexts.default;

const getCardConfigKey = (travellers) => {
    if (travellers.length === 2) {
        return 'couple';
    } else if (
        (travellers.length === 3 || travellers.length === 4) &&
        travellers.some(
            (traveller) =>
                traveller.salutation === 'CHILD' || traveller.age <= 17,
        )
    ) {
        return 'family';
    }

    return 'default';
};

export { getCardConfigKey, getImageName, getAdvantages };
