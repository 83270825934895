import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import moment from 'moment';

import MietwagenBooking from './MietwagenBooking';
import MietwagenBookingEmpty from './MietwagenBookingEmpty';
import { useRentalcarBookings } from './useRentalcarBookings';
import { getImageUrl } from '../../../lib/rentalcar/getImageUrl';
import { BookingListContainer } from './utils/BookingListContainer';
import { BookingListTitle } from './utils/BookingListTitle';
import { BookingsContainer } from './utils/BookingsContainer';

const sortBookigsForStatus = (bookings, status) => {
    const now = moment();
    return bookings
        .filter((booking) => status.includes(booking.status))
        .sort((currentBooking, otherBooking) => {
            const pickupMoment = moment(currentBooking.pickupDatetime);
            const dropoffMoment = moment(currentBooking.dropoffDatetime);

            if (now.isBetween(pickupMoment, dropoffMoment)) {
                return -1;
            }

            return moment(otherBooking.pickupDatetime).diff(pickupMoment);
        });
};

function MietwagenBookingList(props, context) {
    const bookings = useRentalcarBookings(context);

    const sortedBookings = [
        ...sortBookigsForStatus(bookings, ['Pending', 'Confirmed']),
        ...sortBookigsForStatus(bookings, ['Failed', 'Cancelled']),
    ];

    return h(BookingsContainer, [
        h(BookingListTitle, 'Meine Mietwagen-Buchungen'),
        h(BookingListContainer, [
            sortedBookings.map((booking, index) =>
                h(MietwagenBooking, {
                    isDefaultOpen: index === 0,
                    booking,
                    titleImage: getImageUrl(booking.pickupStation.countryName),
                    key: booking.id,
                }),
            ),
            bookings.length === 0 &&
                h(MietwagenBookingEmpty, { config: context.config }),
        ]),
    ]);
}

MietwagenBookingList.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
};

export default MietwagenBookingList;
